import React from "react"
import { Col, Row } from 'reactstrap';

const CarouselBanner = () => (  
   <div class="carousel-banner text-center">
   <Row>
   	<Col>
   	<p className="font-30 text-bold">We do more than just “grab and go” sales</p>
    <p className="carousel-banner-desc">View all of the services we have to offer from prescription refilling and over the counter products to healthcare advices and detailing solutions of health condition.</p>
   	</Col>
   </Row>
    
  </div>
)

export default CarouselBanner