import React from "react"
import { withPrefix } from "gatsby"
import { Col, Row } from 'reactstrap';
import { FaMortarPestle } from 'react-icons/fa';
import { Link } from  "gatsby"

const ServiceBanner = () => (  
   <div class="service">

   <Row className="text-center">
   <Col>
    <p class="text-center font-30 font-dark text-bold">Services</p>
    <FaMortarPestle size="2.5em" className="banner-icon"/>
    </Col>
    </Row>
    <br/>
    <br/>

    <Row>
    	<Col md="6">
    		<Row className="padding-20">
	    		<Col md="4" className="text-center" >
	    			<img className="img-fluid" src={withPrefix('/assets/svg/prescription.svg')} alt="Logo" width="150px" height="auto"  />
					
	    		</Col>

	    		<Col md="8">
	    			<p className="title font-20 text-bold">Prescription Refilling</p>
	    			<p className="description font-14">Let us know what medications you are taking and have your medications refilled hassle-free.
	    			Medication that requires special order  can be sourced, if available, at no extra charges.</p>
	    			<div><Link to="/service"><button type="submit" className="btn green-hollow-btn">Read More</button></Link></div>
	    		</Col>
    		</Row>
    	</Col>
    	<Col md="6">
	    	<Row className="padding-20">
		    		<Col md="4" className="text-center">
		    			<img src={withPrefix('/assets/svg/health.svg')} alt="Logo" width="150px" height="150px" class="img-fluid"/>
		    		</Col>

		    		<Col md="8">
		    			<p className="title font-20 text-bold">Basic Healthcare Monitoring</p>
		    			<p className="description font-14">Blood pressure testing, blood sugar monitoring, cholesterol lipid profile analysis and body fat composition analysis available over here and get the results within minutes. </p>
	    				<div><Link to="/service"><button type="submit" className="btn green-hollow-btn">Read More</button></Link></div>
	    		</Col>
    		</Row>
    	</Col>
    </Row>

    <Row>
    	<Col md="6">
    		<Row className="padding-20">
	    		<Col md="4" className="text-center">
	    			<img src={withPrefix('/assets/svg/epay.svg')} alt="Logo" width="160px" height="160px" class="img-fluid"/>
	    		</Col>

	    		<Col md="8">
	    			<p className="title font-20 text-bold">Electronic Payment Service </p>
	    			<p className="description font-14">No matter where you are, stay connected with Mobile Reloads here. Say goodbye to long queue and enjoy the convenience of paying multiple utilities bill here.  </p>
	    			<div><Link to="/service"><button type="submit" className="btn green-hollow-btn">Read More</button></Link></div>
	    		</Col>
    		</Row>
    	</Col>
    	<Col md="6">
	    	<Row className="padding-20">
		    		<Col md="4" className="text-center">
		    			<img src={withPrefix('/assets/svg/waste.svg')} alt="Logo" width="150px" height="150px" class="img-fluid"/>
		    		</Col>

		    		<Col md="8">
		    			<p className="title font-20 text-bold">Drug Container Waste Recycle</p>
		    			<p className="description font-14">Recycle takes little effort on your part, for a big difference to our world. Send your empty medicine box, medicine or supplement bottles, empty insulin pen and empty inhaler here to us.‎</p>
	    			<div><Link to="/service"><button type="submit" className="btn green-hollow-btn">Read More</button></Link></div>
	    		</Col>
    		</Row>
    	</Col>
    </Row>
  </div>
)

export default ServiceBanner

