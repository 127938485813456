import React from "react"
import { withPrefix } from "gatsby"
import { Col, Row } from 'reactstrap';
import { FaStar, FaCommentDots, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';


const TestimonialBanner = () => (  
   <div class="testimonial text-center padding-5p">
   <ScrollAnimation animateIn="slideInDown" offset="0">
	  <div class="font-25 text-bold font-dark">What our customers are saying</div>
	</ScrollAnimation>
    <br/>
    <FaCommentDots size="2.5em" className="banner-icon"/>
    <br/>
    <br/>

   	<Row>
   		<Col md="4" className="text-center">
   			<img src={withPrefix('/assets/img/testimonial-1.png')} alt="Logo" width="150px" height="150px" />
   			<br/><br/>
			<p>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
			</p>
			<p className=" text-bold font-18 text-dark-green">Mariam Bee binti Jaafar</p>
			<div style={{ width:"80%",margin:"auto"}}>

			<div className=" testimonial-text text-italic">
			 The staff and pharmacist are <br/>very helpful, attentive and friendly. 
			 They always recommend good solutions to my medical 
			 problems.
				<div className="testimonial-quote-left"><FaQuoteLeft size="1.2em" color="#bedcdf"/></div>
				<div className="testimonial-quote-right"><FaQuoteRight size="1.2em" color="#bedcdf"/></div>
			</div>
			
				{/*<p class="text-right">- 67 years old</p>*/}
			</div>
   		</Col>

   		<Col md="4" className="text-center">
   			<img src={withPrefix('/assets/img/testimonial-2.png')} alt="Logo" width="150px" height="150px" />
   			<br/><br/>
			<p>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
			</p>
			<p className=" text-bold font-18 text-dark-green">Normond A/L Rasasing</p>
			<div style={{ width:"80%",margin:"auto"}}>
			
			<div className=" testimonial-text text-italic">
			I switched over to Interaid <br/>Pharmacy for my meds two years ago and I wasn't disappointed. They provide really good and efficient services. Highly recommend!
				<div className="testimonial-quote-left"><FaQuoteLeft size="1.2em" color="#bedcdf"/></div>
				<div className="testimonial-quote-right"><FaQuoteRight size="1.2em" color="#bedcdf"/></div>
			</div>
			
			</div>
   		</Col>


   		<Col md="4" className="text-center">
   			<img src={withPrefix('/assets/img/testimonial-3.png')} alt="Logo" width="150px" height="150px" />
   			<br/><br/>
			<p>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
				<FaStar color="#fc7e59"/>
			</p>
			<p className=" text-bold font-18 text-dark-green">Alanis Lee</p>
			<div style={{ width:"80%",margin:"auto"}}>

			<div className=" testimonial-text text-italic">My favorite pharmacy! <br/>The pharmacist and staff are always pleasant and very professional. 
				<div className="testimonial-quote-left"><FaQuoteLeft size="1.2em" color="#bedcdf"/></div>
				<div className="testimonial-quote-right"><FaQuoteRight size="1.2em" color="#bedcdf"/></div>

			</div>
			
			</div>
   		</Col>

   	</Row>
  </div>
)

export default TestimonialBanner

