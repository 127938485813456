import React from "react"
import AliceCarousel from 'react-alice-carousel';
import { withPrefix } from "gatsby"
import { Container, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { FaFlask} from 'react-icons/fa';
import VisibilitySensor from 'react-visibility-sensor';

// const BrandBanner = () => (  
  
//   const handleOnDragStart = e => e.preventDefault()


//   return (
//    // <div class="brand">
  
//      <AliceCarousel mouseDragEnabled >
//        <img src="/img1" onDragStart={handleOnDragStart} className="yours-custom-class" />
//        <img src="/img2" onDragStart={handleOnDragStart} className="yours-custom-class" />
//        <img src="/img3" onDragStart={handleOnDragStart} className="yours-custom-class" />
//        <img src="/img4" onDragStart={handleOnDragStart} className="yours-custom-class" />
//        <img src="/img5" onDragStart={handleOnDragStart} className="yours-custom-class" />
//      </AliceCarousel>
//       // </div>
// )


class BrandBanner extends React.Component {
  state = {
    // galleryItems: [{withPrefix('/assets/img/carousel-main-img.jpg')},{withPrefix('/assets/img/carousel-main-img.jpg')}]
    // .map((i) => (<div key={i}>{i}</div>)),
    didViewCountUp: false
  }
 
 onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }

  responsive = {
    0: { items: 1 },
    1024: { items: 7 },
  }
 
  onSlideChange(e) {
    console.debug('Item`s position during a change: ', e.item)
    console.debug('Slide`s position during a change: ', e.slide)
  }
 
  onSlideChanged(e) {
    console.debug('Item`s position after changes: ', e.item)
    console.debug('Slide`s position after changes: ', e.slide)
  }
 
  render() {
    return (
      
      <Container fluid="true" className="brand">
      <hr className="brand-divider"/>
         
     <Row>
      <Col>
        <div className="font-dark font-25 ">We carry more than</div>
        <VisibilitySensor onChange={this.onVisibilityChange}>
         <CountUp className="font-dark text-bold-500 font-50 text-orange" start={0} end={this.state.didViewCountUp ? 512 : 0}  duration={2}/>

        </VisibilitySensor>
        <div className="font-30 text-bold">Brands</div><br/>
        <FaFlask size="2.5em" className="banner-icon"/>
      </Col>
      </Row>
      <div class="brand-carousel-divider"></div>

        <Row className="brand-carousel">
          
            <Col>
             <AliceCarousel
            // items={this.state.galleryItems}
            responsive={this.responsive}
            buttonsDisabled = {true}
            autoPlayInterval={2000}
            autoPlayDirection="ltr"
            dotsDisabled = {true}
            autoPlay={true}
            fadeOutAnimation={false}
            mouseDragEnabled={false}
            playButtonEnabled={false}
            disableAutoPlayOnAction={false}
            onSlideChange={this.onSlideChange}
            onSlideChanged={this.onSlideChanged}
          >
       
          <div><img src={withPrefix('/assets/img/uriage.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/biolife.png')} alt="Logo" height="30px" width="140px" /></div>
          <div><img src={withPrefix('/assets/img/blackmores.png')} alt="Logo" height="50px" width="150px" /></div>
          <div><img src={withPrefix('/assets/img/gnc.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/kordel.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/pureherbs.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/moogoo.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/lamoon.png')} alt="Logo" height="60px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/himalaya.png')} alt="Logo" height="50px" width="120px" /></div>
          <div><img src={withPrefix('/assets/img/lifeglue.png')} alt="Logo" height="50px" width="150px" /></div>
          <div><img src={withPrefix('/assets/img/dentiste.png')} alt="Logo" height="50px" width="150px" /></div>
          <div><img src={withPrefix('/assets/img/megalive.png')} alt="Logo" height="50px" width="140px" /></div>
          <div><img src={withPrefix('/assets/img/nova.png')} alt="Logo" height="50px" width="130px" /></div>
          <div><img src={withPrefix('/assets/img/sebamed.png')} alt="Logo" height="50px" width="100px" /></div>
          <div><img src={withPrefix('/assets/img/rocs.png')} alt="Logo" height="50px" width="130px" /></div>

          </AliceCarousel>
          </Col>
          </Row>

      </Container>
    )
  }
}
export default BrandBanner

