import React from "react"
import { withPrefix } from "gatsby"
// import logo from "../carousel-main-img.jpeg"
// class HomeCarousel extends React.Component {
// 	constructor(props) {
// 	    super(props);
// 	    this.state = { activeIndex: 0 };
// 	    this.next = this.next.bind(this);
// 	    this.previous = this.previous.bind(this);
// 	    this.goToIndex = this.goToIndex.bind(this);
// 	    this.onExiting = this.onExiting.bind(this);
// 	    this.onExited = this.onExited.bind(this);
// 	  }
// 	  onExiting() {
// 	    this.animating = true;
// 	  }
// 	  onExited() {
// 	    this.animating = false;
// 	  }
// 	  next() {
// 	    if (this.animating) return;
// 	    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
// 	    this.setState({ activeIndex: nextIndex });
// 	  }
// 	  previous() {
// 	    if (this.animating) return;
// 	    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
// 	    this.setState({ activeIndex: nextIndex });
// 	  }
// 	  goToIndex(newIndex) {
// 	    if (this.animating) return;
// 	    this.setState({ activeIndex: newIndex });
// 	  }
// render() {
//     const { activeIndex } = this.state;
//     const slides = items.map((item) => {
//       return (
//         <CarouselItem onExiting={this.onExiting} onExited={this.onExited}
//           key={item.src} cssModule={CarouselStyling}
//         >
//           <img src={item.src} alt={item.altText} />
//           <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
//         </CarouselItem>
//       );
//     });
//     return (
//       <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride={false} cssModule={CarouselStyling}
//       >
//         <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
//         {slides}
//         <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
//         <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
//       </Carousel>
//     );
//   }
// }
const HomeCarousel = () => (
	<div class="home-carousel">
		<div class="image"><img src={withPrefix('/assets/img/carousel-main-img.jpg')} alt="Logo" height="auto" width="auto" class="img-fluid"/></div>
		<div class="carousel-desc">
			<p class="font-40 text-bold font-test">Interaid Pharmacy</p>
			<p class="font-25 "><span class="blur-highlight-text">The legacy of a local, independent pharmacy continues and strives with Interaid Pharmacy.
 Visit us and experience good ole down-home service.</span>
  </p>
			<button type="submit" className="btn yellow-full-btn font-16"><a href="/about">Know Us More</a></button>
		</div>
	</div>
)
export default HomeCarousel