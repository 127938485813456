// HOME PAGE

import React from "react"
import  "../styles/global.scss"
import  "../styles/responsive.scss"
import Layout from "../layouts/index"
import HomeCarousel from "../components/HomeCarousel";
import CarouselBanner from "../components/CarouselBanner";

import ServiceBanner from "../components/ServiceBanner";
import MembershipBanner from "../components/MembershipBanner";
import BrandBanner from "../components/BrandBanner";
import TestimonialBanner from "../components/TestimonialBanner";
// import './bootstrap.min.css';
// import { Jumbotron, Button } from 'react-bootstrap';


const Index = () => (  
   <Layout> 
    <section class="home-section" id="carousel">
    	<HomeCarousel></HomeCarousel>
    </section>

    <section class="home-section" id="desc">
        <CarouselBanner></CarouselBanner>
    </section>

    <section class="home-section" id="service">
        <ServiceBanner></ServiceBanner>
    </section>

    <section class="home-section" id="membership">
        <MembershipBanner></MembershipBanner>
    </section>
    
    <section class="home-section" id="testimonial">
        <TestimonialBanner></TestimonialBanner>
    </section>
    

    <section class="home-section" id="brand">
        <BrandBanner></BrandBanner>
    </section>
    

  </Layout>
)

export default Index

// <Link to="/page-2/">Go to page 2</Link>