import React from "react"
import { withPrefix } from "gatsby"

const MembershipBanner = () => (  
   <div class="membership">
    <a href="/membership"><img src={withPrefix('/assets/img/Membership.jpg')} alt="Logo" height="300px" width="3000px" class="img-fluid"/></a>
  </div>
)

export default MembershipBanner

